<template>
  <div class="message_leaving">
    <div
      class="message_leaving_ul"
      v-for="(item, index) in messageList"
      :key="index"
      @mouseenter="hoverItem(item)"
      @mouseleave="hideItem(item)"
    >
      <div class="message_leaving_ul_li padd_ri50">
        <img :src="item.image" alt="" />
        <div class="message_leaving_ul_li_right">
          <div>{{ item.nickName }}</div>
          <p>
            <span>{{ item.content }}</span>
            <em>{{ item.createTime }}</em>
          </p>
        </div>
        <div class="show_icon" v-if="item.defaults">
          <i
            class="el-icon-star-off"
            v-if="!item.isShow"
            @click="subimit(item)"
          />
          <i
            class="el-icon-star-off"
            style="color: #ffa800"
            v-else
            @click="subimit(item)"
          />
          <i
            class="el-icon-delete"
            style="margin-left: 20px"
            @click="deleteItem(item)"
          />
        </div>
      </div>
      <div
        class="message_leaving_ol"
        v-for="(tt, ii) in item.panelDiscussionViewList"
        :key="ii"
        @mouseenter="hoverItemOl(item)"
        @mouseleave="hideItemOl(item)"
      >
        <div class="message_leaving_ul_li">
          <img :src="tt.image" alt="" />
          <div class="message_leaving_ul_li_right">
            <div>{{ tt.nickName }}</div>
            <p>
              <span>{{ tt.content }}</span>
              <em>{{ tt.createTime }}</em>
            </p>
          </div>
          <div class="show_icon" v-if="item.defaults">
            <i
              class="el-icon-star-off"
              v-if="!tt.isShow"
              @click="subimit(tt)"
            />
            <i
              class="el-icon-star-off"
              style="color: #ffa800"
              v-else
              @click="subimit(tt)"
            />
            <i
              class="el-icon-delete"
              style="margin-left: 20px"
              @click="deleteItem(tt)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="special_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { messageListBy } from "@/api/special.js";
import { panelDiscussion, panelDiscussionDetele } from "@/api/message.js";
import { pagination } from "@/mixins/pagination";
const defaultQueryInfo = {
  basicId: "", //专题id
  pageNumber: 0, // 页码
  pageSize: 10, //每页显示个数
};
export default {
  name: "messageLeaving",
  mixins: [pagination],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      messageList: [],
      queryInfo: { ...defaultQueryInfo },
    };
  },
  created() {
    this.getMessage();
  },
  methods: {
    //父级点击显示在小程序
    async subimit(row) {
      const { data: res } = await panelDiscussion({ id: row.id });
      if (res.code === 10200) {
        this.$message.success("操作成功");
        this.getMessage();
      } else {
        this.$message.error("审核失败，稍候重试");
      }
    },
    deleteItem(row) {
      this.$confirm(`确认删除${row.content}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await panelDiscussionDetele({ id: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.content}删除成功`);
            this.getMessage();
          }
        })
        .catch(() => {});
    },
    //鼠标进入
    hoverItemOl(row) {
      row.defaults = true;
    },
    //鼠标移除
    hideItemOl(row) {
      row.defaults = false;
    },
    //鼠标进入
    hoverItem(row) {
      row.defaults = true;
    },
    //鼠标移除
    hideItem(row) {
      row.defaults = false;
    },
    async getMessage() {
      this.queryInfo.basicId = this.id;
      const { data: res } = await messageListBy(this.queryInfo);
      if (res.success) {
        this.messageList = res.data.data;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.message_leaving {
  width: 100%;
  overflow: auto;
  height: 700px;

  .special_page {
    margin-top: 60px;
  }

  .message_leaving_ul {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .padd_ri50 {
      padding-right: 50px;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
    }

    .message_leaving_ol {
      box-sizing: border-box;
      padding: 0 50px;
    }

    .message_leaving_ul_li {
      width: 100%;
      overflow: hidden;
      display: flex;
      margin-bottom: 20px;
      flex-direction: row;
      padding-bottom: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;

      img {
        width: 40px;
        border-radius: 50%;
        height: 40px;
        overflow: hidden;
        margin-right: 10px;
      }

      .message_leaving_ul_li_right {
        flex: 1;
        overflow: hidden;

        div {
          color: #8d8d8d;
          font-size: 14px;
          font-weight: 400;
        }

        p {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        span {
          color: #151515;
          font-size: 16px;
          margin: 4px 0;
          font-weight: 400;
        }

        em {
          font-style: normal;
          color: #8d8d8d;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>