<template>
  <div class="information_special">
    <el-form
      :model="basicProjectQueries"
      :rules="rules"
      ref="ruleFormInformation"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="专题名称" prop="name">
        <el-input
          v-model="basicProjectQueries.name"
          style="width: 70%"
          placeholder="请输入"
        />
      </el-form-item>
      <!-- <el-form-item label="活动banner" prop="image">
        <el-upload
          class="avatar-uploader"
          action=""
          :http-request="upload"
          :show-file-list="false"
          accept="image/*"
        >
          <img
            v-if="basicProjectQueries.image"
            :src="basicProjectQueries.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="addinfo_title">(图片比例：3:2)</div>
      </el-form-item> -->
      <el-form-item label="先导视频" prop="image">
        <el-upload
          v-loading="loading"
          class="avatar-uploader-video"
          action
          :http-request="uploadVideo"
          :before-upload="beforeUploadVideo"
          :show-file-list="false"
        >
          <video
            v-if="basicProjectQueries.image != '' && !videoFlag"
            v-bind:src="basicProjectQueries.image"
            class="avatar-video video-avatar"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
          <i
            v-else-if="basicProjectQueries.image == '' && !videoFlag"
            class="el-icon-plus avatar-uploader-video-icon"
          ></i>
        </el-upload>
        <div class="addinfo_title">视频大小不能超过150MB</div>
      </el-form-item>
      <el-form-item label="视频封面">
        <img
          :src="basicProjectQueries.picture"
          v-if="basicProjectQueries.picture"
        />
      </el-form-item>
      <el-form-item label="专题标签">
        <div>
          <el-tag
            v-for="(item, index) in tagList"
            :key="index"
            closable
            @close="handleClose(item)"
            >{{ item }}</el-tag
          >
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+自定义标签</el-button
          >
        </div>

        <el-tag
          :key="index"
          @click="addTag(tag.codeName)"
          v-for="(tag, index) in dynamicTags"
        >
          {{ tag.codeName }}
        </el-tag>
      </el-form-item>
      <el-form-item label="专题简介" prop="introduction">
        <!-- <ueditor
          :value="basicProjectQueries.introduction"
          :config="config"
          ref="ue"
        /> -->
        <div class="is_vip">
          <editorbar
            style="width: 70%"
            v-model="basicProjectQueries.introduction"
            :isClear="isClear"
          />
          <div class="check_new">
            <el-checkbox
              @change="bookShowType"
              :value="basicProjectQueries.isShow"
              >仅会员可看</el-checkbox
            >
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="submit_btn" v-if="specialType">
      <el-button type="primary" @click="subimitInformation">保存</el-button>
      <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
    </div>
  </div>
</template>

<script>
import { uploadAdminVideo, getByVideoImage } from "@/api/commonVideo.js";
import { editSpecila } from "@/api/special.js";
import Editorbar from "../../../components/Editorbar.vue";
import Ueditor from "../../../components/ue/ueditor.vue";
import UpLoadVideo from "../../../components/UpLoadVideo.vue";
export default {
  components: { Editorbar, Ueditor, UpLoadVideo },
  name: "informationSpecial",
  props: {
    specialType: {
      type: Number,
    },
    basicProjectQueries: {
      type: Object,
    },
    activeNameTab: {
      type: String,
    },
    dynamicTags: {
      type: Array,
    },
    tagList: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      //新建标签
      inputVisible: false,
      inputValue: "",
      rules: {
        //验证规则
        name: [{ required: true, message: "请输入专题名称", trigger: "blur" }],
        // image: [{ required: true, message: "请上传活动banner" }],
        image: [{ required: true, message: "请输入先导视频", trigger: "blur" }],
        tagList: [{ required: true, message: "请选择标签", trigger: "blur" }],
        introduction: [
          { required: true, message: "请输入专题简介", trigger: "blur" },
        ],
      },
      isClear: false,
      config: {
        initialFrameHeight: 400,
        initialFrameWidth: 1200,
        toolbars: [
          [
            "fontsize",
            "|",
            "paragraph",
            "forecolor",
            "bold",
            "italic",
            "blockquote",
            "insertunorderedlist",
            "insertorderedlist",
            "justifycenter",
            "link",
            "unlink",
            "source",
            "undo",
            "redo",
          ],
        ],
      },
    };
  },
  watch: {
    activeNameTab: {
      handler: function (newVal) {
        if (newVal === "first") {
        }
      },
      immediate: true,
    },
  },
  methods: {
    bookShowType(val) {
      this.$emit("booean", val);
    },
    //上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 150;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        this.$message("视频大小不能超过150MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    async uploadVideo(file) {
      this.loading = true;
      // this.fileList = []
      const form = new FormData();
      form.append("file", file.file);
      const { data: res } = await uploadAdminVideo(form);
      if (res.code === 10200) {
        this.loading = false;
        this.basicProjectQueries.image = res.data.data.mediaUrl;
        this.basicProjectQueries.fileId = res.data.data.fileId;
        this.isShowUploadVideo = true;
        this.$message.success("上传成功");
        setTimeout(() => {
          this.getImgageUrl();
        }, 15000);
        // this.$emit('updateImg', this.testList)
      } else {
        this.$message.error("上传失败，请重新上传。");
        this.loading = false;
        this.isShowUploadVideo = false;
      }
    },
    //获取上传视频的封面图
    async getImgageUrl() {
      const { data: res } = await getByVideoImage({
        fileId: this.basicProjectQueries.fileId,
      });
      if (res.code === 10200) {
        this.basicProjectQueries.picture = res.data;
      }
    },
    //编辑
    subimitInformation() {
      // this.basicProjectQueries.introduction = this.$refs.ue.getUEContent();
      this.$refs.ruleFormInformation.validate(async (valid) => {
        if (valid) {
          const { data: res } = await editSpecila(this.basicProjectQueries);
          if (res.success) {
            this.$message.success("修改成功!");
            this.$router.go(-1);
          } else {
            this.$message.error("修改失败。请稍候重试。");
          }
        } else {
          this.$message.error("请完善基本信息");
        }
      });
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //添加标签
    addTag(row) {
      if (this.tagList.indexOf(row) < 0) {
        this.tagList.push(row);
        this.basicProjectQueries.tag = this.tagList.join(",");
      }
    },
    //删除标签
    handleClose(row) {
      this.tagList.splice(this.tagList.indexOf(row), 1);
      this.basicProjectQueries.tag = this.tagList.join(",");
    },
    //自定义标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagList.push(inputValue);
        this.basicProjectQueries.tag = this.tagList.join(",");
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //上传图片
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const { data: res } = await uploadAdmin(form);
      if (res.success) {
        this.basicProjectQueries.image = res.data.fileLook[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.information_special {
  width: 100%;

  .submit_btn {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
  }

  .demo-ruleForm {
    width: 80%;
    margin: 0 auto;

    img {
      width: 145px;
      height: 84px;
    }
  }

  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-tag {
    margin-right: 12px;
    cursor: pointer;
  }

  .input-new-tag {
    width: 90px;
    vertical-align: bottom;
  }

  /deep/ .el-upload--picture-card {
    width: 88px;
    height: 88px;
    line-height: 96px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 88px;
    height: 88px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }

  .addinfo_title {
    font-size: 14px;
    color: #999;
  }

  .avatar-uploader-video .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-video .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-video-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 240px;
    height: 240px;
    line-height: 240px;
    text-align: center;
  }
  .avatar-video {
    width: 240px;
    height: 240px;
    display: block;
  }
  .is_vip {
    display: flex;
    flex-direction: row;

    .check_new {
      width: 120px;
      margin-left: 20px;
    }
  }
}
</style>