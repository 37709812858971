<template>
  <div class="special_info">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <information-special
          class="tab_add"
          ref="information"
          @booean="cardShow"
          :basicProjectQueries.sync="basicProjectListQuery.basicProjectQueries"
          :tagList="tagList"
          :activeNameTab="activeName"
          :dynamicTags="dynamicTags"
          :specialType="specialType * 1"
        />
      </el-tab-pane>
      <el-tab-pane label="相关视频" name="second">
        <related-video
          class="tab_add"
          @booean="videoShowItem"
          :activeNameTab="activeName"
          :relatedVideoListQueries.sync="
            basicProjectListQuery.relatedVideoListQueries
          "
          :id="id"
          :specialType="specialType * 1"
          :videoTagsList="videoTagsList"
          @updateList="updateList"
        />
      </el-tab-pane>
      <el-tab-pane label="推荐书籍" name="third">
        <!-- <recommended-books
          class="tab_add"
          :activeNameTab="activeName"
          :specialType="specialType * 1"
          :id="id"
          :recommendedBooksListQueries.sync="
            basicProjectListQuery.recommendedBooksListQueries
          "
        /> -->
        <tui-books
          :activeNameTab="activeName"
          :specialType="specialType * 1"
          :id="id"
          :recommendedBooksListQueries.sync="
            basicProjectListQuery.recommendedBooksListQueries
          "
        />
      </el-tab-pane>
      <el-tab-pane name="fourth" v-if="specialType * 1">
        <span slot="label" @click="countClick">
          <el-badge v-if="!showLiu" :value="count" class="item">
            讨论
          </el-badge>
          <span v-else>讨论</span>
        </span>
        <message-leaving :id="id" />
      </el-tab-pane>
    </el-tabs>
    <div v-if="messageBootn">
      <div class="submit_btn" v-if="!(specialType * 1)">
        <el-button type="primary" @click="submitSpecial">保存</el-button>
        <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { codeType } from "@/api/common.js";
import {
  AddSpecial,
  specialFindById,
  relatedVideoJie,
  recommendedBooksJie,
  messageUnread,
  deleteCount,
} from "@/api/special.js";
import informationSpecial from "./informationSpecial.vue";
// import recommendedBooks from "./recommendedBooks.vue";
import relatedVideo from "./relatedVideo.vue";
import TuiBooks from "./tuiBooks.vue";
import messageLeaving from "./messageLeaving.vue";
export default {
  //recommendedBooks
  components: { relatedVideo, informationSpecial, TuiBooks, messageLeaving },
  name: "specialInfo",
  computed: {
    // 0新增 1编辑
    specialType() {
      return this.$route.query.specialType;
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.getMessageCount();
    this.dynamicTagsList();
    this.dyVideoTagsList();
    if (this.specialType * 1) {
      this.searchInforation();
      this.searchVideo();
      this.searchBooks();
    }
  },
  data() {
    return {
      showLiu: true,
      count: 0, //未读留言的数量
      messageBootn: true, //留言不显示保存
      //基本信息标签选中
      tagList: [],
      activeName: "first",
      //视频标签
      videoTagsList: [],
      //基本信息标签
      dynamicTags: [],
      basicProjectListQuery: {
        basicProjectQueries: {
          image: "", //  banner图
          introduction: "", //专题简介
          name: "", //专题名称
          tag: "", //标签
          fileId: "",
          picture: "",
          isShow: false,
        },
        relatedVideoListQueries: [
          {
            video: "", //视频地址
            id: "", //主键编号
            videoFalse: false,
            videoIntroduction: "", //视频简介
            videoImage: "",
            videoName: "", //视频名称
            videoTag: "", //  视频标签
            //新建标签
            inputVisible: false,
            inputValue: "",
            tagListVideo: [],
            fileId: "",
            picture: "",
            videoIsShow: false,
          },
        ],
        recommendedBooksListQueries: [
          {
            author: "", //作者
            bookAbstract: "", //书籍简介
            bookName: "", //书籍名称
            buyLinks: "", //购买链接
            heatValue: "", //热度值
            images: "", //  书籍图片
          },
        ],
      },
    };
  },
  methods: {
    //专题简介
    cardShow(val) {
      this.basicProjectListQuery.basicProjectQueries.isShow = val;
    },
    //视频
    videoShowItem(val) {
      this.basicProjectListQuery.relatedVideoListQueries[
        val.index
      ].videoIsShow = val.boolean;
    },
    //消除红点
    async countClick() {
      const query = {
        basicId: this.id * 1,
      };
      const { data: res } = await deleteCount(query);
      if (res.code === 10200) {
        this.showLiu = true;
      }
    },
    //获取未读消息的数量
    async getMessageCount() {
      const query = {
        basicId: this.id * 1,
      };
      const { data: res } = await messageUnread(query);
      if (res.success) {
        this.count = res.data;
        if (this.count === 0) {
          this.showLiu = true;
        }
      } else {
        this.showLiu = true;
      }
    },
    //tab选择
    handleClick() {
      if (this.activeName === "fourth") {
        this.messageBootn = false;
      } else {
        this.messageBootn = true;
      }
    },
    //视频标签回显
    updateList(val) {},
    //获取书籍数据
    async searchBooks() {
      const { data: res } = await recommendedBooksJie({ id: this.id });
      if (res.data) {
        if (res.data.length > 0) {
          this.basicProjectListQuery.recommendedBooksListQueries = res.data;
        }
      }
    },
    //获取视频数据
    async searchVideo() {
      const { data: res } = await relatedVideoJie({ id: this.id });
      if (res.data.length > 0) {
        this.basicProjectListQuery.relatedVideoListQueries = res.data;
        // res.data.forEach((el) => {
        //   if (el.videoTag) {
        //     el.tagListVideo = el.videoTag.split(",");
        //   }
        // });
      }
    },
    //获取基础数据
    async searchInforation() {
      const { data: res } = await specialFindById({ id: this.id });
      if (res.data) {
        this.basicProjectListQuery.basicProjectQueries = res.data;
        this.tagList =
          this.basicProjectListQuery.basicProjectQueries.tag.split(",");
        // this.$refs.information.$refs.ue.setUEContent(res.data.introduction);
      }
    },
    //submitSpecial  保存
    submitSpecial() {
      // this.basicProjectListQuery.basicProjectQueries.introduction =
      //   this.$refs.information.$refs.ue.getUEContent();
      if (this.basicProjectListQuery.relatedVideoListQueries[0].video) {
        this.$refs.information.$refs.ruleFormInformation.validate(
          async (valid) => {
            if (valid) {
              const { data: res } = await AddSpecial(
                this.basicProjectListQuery
              );
              if (res.success) {
                this.$message.success("新增成功!");
                this.$router.go(-1);
              } else {
                this.$message.error("新增失败，请稍候重试");
              }
            } else {
              this.activeName = "first";
              this.$message.error("请完善基本信息");
            }
          }
        );
      } else {
        this.activeName = "second";
        this.$message.error("请添加相关视频");
      }
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //获取标签列表
    async dynamicTagsList() {
      const { data: res } = await codeType({ codeType: "001" });
      if (res.data) {
        this.dynamicTags = res.data;
      }
    },
    //获取视频标签列表
    async dyVideoTagsList() {
      const { data: res } = await codeType({ codeType: "002" });
      if (res.data) {
        this.videoTagsList = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.special_info {
  width: 100%;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;

  /deep/ .el-tabs__nav-wrap {
    overflow: inherit;
  }

  /deep/ .el-tabs__nav-scroll {
    overflow: inherit;
  }

  .submit_btn {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  /deep/ .tab_add {
    height: 580px;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 60px;
  }

  /deep/ .el-card {
    border: none;
  }

  /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/.el-tabs__item.is-active {
    color: #8483d0;
  }

  /deep/.el-tabs__active-bar {
    background-color: #8483d0;
  }

  /deep/ .el-tabs__item:hover {
    color: #8483d0;
    cursor: pointer;
  }
}
</style>