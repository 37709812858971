<template>
  <div class="related_video">
    <el-form
      label-position="right"
      label-width="100px"
      class="information_table_form"
    >
      <div
        class="related_video_list"
        v-for="(item, index) in relatedVideoListQueries"
        :key="index"
      >
        <el-row :gutter="10">
          <el-col :span="14">
            <el-form-item prop="video" :label="'视频' + (index + 1)">
              <!-- <el-input
                placeholder="b站的视频，请复制的嵌入代码，并加上https"
                clearable
                v-model="item.video"
              /> -->
              <div @click="getImageTypeIndex(index)">
                <el-upload
                  v-loading="item.videoFalse"
                  class="avatar-uploader-video"
                  action
                  :http-request="uploadVideo"
                  :before-upload="beforeUploadVideo"
                  :show-file-list="false"
                >
                  <video
                    v-if="item.video != '' && !videoFlag"
                    :src="item.video"
                    class="avatar-video video-avatar"
                    controls="controls"
                  >
                    您的浏览器不支持视频播放
                  </video>
                  <i
                    v-else-if="item.video == '' && !videoFlag"
                    class="el-icon-plus avatar-uploader-video-icon"
                  ></i>
                </el-upload>

                <div class="addinfo_title">视频大小不能超过150MB</div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button
              icon="el-icon-plus"
              circle
              type="success"
              plain
              @click="addDomain"
            ></el-button>
            <el-button
              icon="el-icon-minus"
              circle
              type="danger"
              plain
              v-if="index"
              @click.prevent="removeDomain(item)"
            ></el-button>
          </el-col>
          <el-col :span="4">
            <el-checkbox
              @change="bookShowType(index, item.videoIsShow)"
              :value="item.videoIsShow"
              >仅会员可看</el-checkbox
            >
          </el-col>
        </el-row>
        <el-form-item label="视频名称" prop="videoName">
          <el-input v-model="item.videoName" style="width: 70%" />
        </el-form-item>
        <el-form-item label="视频封面">
          <img :src="item.picture" v-if="item.picture" />
        </el-form-item>
        <!-- <el-form-item label="视频封面">
          <div @click="getImageTypeIndex(index)">
            <el-upload
              class="avatar-uploader"
              action=""
              accept="image/*"
              :http-request="upload"
              :show-file-list="false"
            >
              <img
                v-if="item.videoImage"
                :src="item.videoImage"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="addinfo_title">(图片比例：3:2)</div>
        </el-form-item> -->
        <el-form-item label="视频标签" prop="tagList">
          <div>
            <el-tag
              v-for="(tt, ii) in item.tagListVideo"
              :key="ii"
              closable
              @close="handleClose(item, tt)"
              >{{ tt }}</el-tag
            >
            <el-input
              class="input-new-tag"
              v-if="item.inputVisible"
              type="text"
              v-model="item.inputValue"
              size="small"
              @keyup.enter.native="handleInputConfirm(item)"
              @blur="handleInputConfirm(item)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput(item)"
              >+自定义标签</el-button
            >
          </div>

          <el-tag
            :key="i"
            @click="addTag(item, tag.codeName)"
            v-for="(tag, i) in videoTagsList"
          >
            {{ tag.codeName }}
          </el-tag>
        </el-form-item>
        <el-form-item label="视频简介" prop="videoIntroduction">
          <el-input
            style="width: 70%"
            v-model="item.videoIntroduction"
            type="textarea"
            show-word-limit
            maxlength="40"
          />
        </el-form-item>
      </div>
    </el-form>
    <div class="submit_btn" v-if="specialType">
      <el-button type="primary" @click="subimitVideo">保存</el-button>
      <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
    </div>
  </div>
</template>

<script>
import { uploadAdminVideo, getByVideoImage } from "@/api/commonVideo.js";
import { uploadAdmin } from "@/api/common.js";
import { videoUpadta } from "@/api/special.js";
export default {
  name: "relatedVideo",
  props: {
    relatedVideoListQueries: {
      type: Array,
    },
    videoTagsList: {
      type: Array,
    },
    specialType: {
      type: Number,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      statefocus: false,
      uploadImageType: "",
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
    };
  },
  methods: {
    bookShowType(index, val) {
      let query = {
        index,
        boolean: !val,
      };
      this.$emit("booean", query);
    },
    //上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 150;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        this.$message("视频大小不能超过150MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    async uploadVideo(file) {
      this.relatedVideoListQueries[this.uploadImageType].videoFalse = true;
      // this.fileList = []
      const form = new FormData();
      form.append("file", file.file);
      const { data: res } = await uploadAdminVideo(form);
      if (res.code === 10200) {
        this.$message.success("上传成功");
        this.relatedVideoListQueries[this.uploadImageType].video =
          res.data.data.mediaUrl;
        this.relatedVideoListQueries[this.uploadImageType].fileId =
          res.data.data.fileId;
        this.relatedVideoListQueries[this.uploadImageType].videoFalse = false;
        this.isShowUploadVideo = true;
        setTimeout(() => {
          this.getImgageUrl();
        }, 15000);
        // this.$emit('updateImg', this.testList)
      } else {
        this.$message.error("上传失败，请重新上传。");
        this.relatedVideoListQueries[this.uploadImageType].videoFalse = false;
        this.isShowUploadVideo = false;
      }
    },
    //获取索引index
    getImageTypeIndex(index) {
      this.uploadImageType = index;
    },
    //上传图片
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const { data: res } = await uploadAdmin(form);
      if (res.success) {
        this.relatedVideoListQueries[this.uploadImageType].videoImage =
          res.data.fileLook[0];
      }
    },
    //获取上传视频的封面图
    async getImgageUrl() {
      const { data: res } = await getByVideoImage({
        fileId: this.relatedVideoListQueries[this.uploadImageType].fileId,
      });
      if (res.code === 10200) {
        this.relatedVideoListQueries[this.uploadImageType].picture = res.data;
      }
    },
    //编辑保存
    async subimitVideo() {
      // this.relatedVideoListQueries.forEach((el) => {
      //   return delete el.inputVisible;
      // });
      // this.relatedVideoListQueries.forEach((el) => {
      //   return delete el.tagListVideo;
      // });
      // this.relatedVideoListQueries.forEach((el) => {
      //   return delete el.inputValue;
      // });
      const query = {
        basicId: this.id,
        queryList: this.relatedVideoListQueries,
      };
      const { data: res } = await videoUpadta(query);
      if (res.success) {
        this.$message.success("修改成功!");
        this.$router.go(-1);
      } else {
        this.$message.error("修改失败，请稍候重试。");
      }
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //添加标签
    addTag(item, row) {
      if (item.tagListVideo.indexOf(row) < 0) {
        item.tagListVideo.push(row);
        item.videoTag = item.tagListVideo.join(",");
      }
    },
    //删除标签
    handleClose(row, el) {
      row.tagListVideo.splice(row.tagListVideo.indexOf(el), 1);
      row.videoTag = row.tagListVideo.join(",");
    },
    //自定义标签
    handleInputConfirm(row) {
      let inputValue = row.inputValue;
      if (inputValue != "") {
        row.tagListVideo.push(inputValue);
        row.videoTag = row.tagListVideo.join(",");
      }
      // console.log(inputValue);
      row.inputVisible = false;
      row.inputValue = "";
    },
    showInput(row) {
      row.inputVisible = true;
    },
    addDomain() {
      this.relatedVideoListQueries.push({
        video: "", //视频地址
        id: "", //主键编号
        videoFalse: false,
        videoIntroduction: "", //视频简介
        videoName: "", //视频名称
        videoTag: "", //  视频标签
        videoImage: "",
        //新建标签
        inputVisible: false,
        inputValue: "",
        tagListVideo: [],
      });
    },
    //删除单个视频信息
    removeDomain(item) {
      var index = this.relatedVideoListQueries.indexOf(item);
      if (index !== -1) {
        this.relatedVideoListQueries.splice(index, 1);
      }
    },
  },
  directives: {
    Focus: {
      update: function (el, { value }) {
        if (value) {
          // if(true)就聚焦
          el.focus();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.related_video {
  width: 100%;
  box-sizing: border-box;

  .avatar-uploader-video .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-video .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-video-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 240px;
    height: 240px;
    line-height: 240px;
    text-align: center;
  }
  .avatar-video {
    width: 240px;
    height: 240px;
    display: block;
  }

  /deep/ .el-upload--picture-card {
    width: 88px;
    height: 88px;
    line-height: 96px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 88px;
    height: 88px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }

  .addinfo_title {
    font-size: 12px;
    color: #c4c4c4;
  }

  .submit_btn {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
  }

  .related_video_list {
    margin-top: 50px;
    width: 100%;

    img {
      width: 145px;
      height: 84px;
    }
  }

  .information_table_form {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-tag {
    margin-right: 12px;
    cursor: pointer;
  }

  .input-new-tag {
    width: 90px;
    vertical-align: bottom;
  }
}
</style>