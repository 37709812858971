<template>
  <div class="recommended_books">
    <div class="recommended_books_title"><span></span>书籍信息</div>
    <el-form
      label-position="right"
      label-width="100px"
      class="information_table_form"
    >
      <div
        class="recommended_books_list"
        v-for="(item, index) in recommendedBooksListQueries"
        :key="index"
      >
        <el-row :gutter="10">
          <el-col :span="16">
            <el-form-item :label="'书籍' + (index + 1) + '名称'">
              <el-select
                v-model="item.booksId"
                filterable
                placeholder="请输入书籍名称"
              >
                <el-option
                  v-for="tt in options"
                  :key="tt.id"
                  :label="tt.bookName"
                  :value="tt.id"
                >
                </el-option>
              </el-select>
              <!-- <el-input v-model="item.bookName" placeholder="请输入" /> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button
              icon="el-icon-plus"
              circle
              type="success"
              plain
              @click="addDomain"
            ></el-button>
            <el-button
              icon="el-icon-minus"
              circle
              type="danger"
              plain
              v-if="index"
              @click.prevent="removeDomain(item)"
            ></el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="submit_btn" v-if="specialType">
      <el-button type="primary" @click="subimitBooks">保存</el-button>
      <el-button plain class="new_btn" @click="cancelClick">取消</el-button>
    </div>
  </div>
</template>

<script>
import { uploadAdmin } from "@/api/common.js";
import { booksUpadta, bookRecoom } from "@/api/special.js";
export default {
  name: "recommendedBooks",
  props: {
    recommendedBooksListQueries: {
      type: Array,
    },
    id: {
      type: String,
    },
    specialType: {
      type: Number,
    },
  },
  data() {
    return {
      options: [],
      uploadImageType: "",
    };
  },
  created() {
    this.getBookList();
  },
  methods: {
    //获取推荐数据模糊查询
    async getBookList() {
      const { data: res } = await bookRecoom({ booksName: "" });
      if (res.success) {
        this.options = res.data;
      }
    },
    //书籍编辑保存
    async subimitBooks() {
      const query = {
        basicId: this.id,
        queryList: this.recommendedBooksListQueries,
      };
      const { data: res } = await booksUpadta(query);
      if (res.success) {
        this.$message.success("编辑成功!");
        this.$router.go(-1);
      } else {
        this.$message.error("编辑失败，请稍候重试。");
      }
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //获取索引index
    getImageTypeIndex(index) {
      this.uploadImageType = index;
    },
    //上传图片
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const { data: res } = await uploadAdmin(form);
      if (res.success) {
        this.recommendedBooksListQueries[this.uploadImageType].images =
          res.data.fileLook[0];
      }
    },
    addDomain() {
      this.recommendedBooksListQueries.push({
        author: "", //作者
        bookAbstract: "", //书籍简介
        bookName: "", //书籍名称
        buyLinks: "", //购买链接
        heatValue: "", //热度值
        images: "", //  书籍图片
      });
    },
    removeDomain(item) {
      var index = this.recommendedBooksListQueries.indexOf(item);
      if (index !== -1) {
        this.recommendedBooksListQueries.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.recommended_books {
  width: 100%;
  overflow: hidden;

  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type="number"] {
    -moz-appearance: textfield;
  }

  .submit_btn {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
  }

  .information_table_form {
    width: 80%;
    margin: 0 auto;

    .recommended_books_list {
      margin-top: 50px;

      /deep/ .el-select {
        width: 100%;
      }
    }
  }

  /deep/ .el-upload--picture-card {
    width: 88px;
    height: 88px;
    line-height: 96px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 88px;
    height: 88px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }

  .addinfo_title {
    font-size: 12px;
    color: #c4c4c4;
  }

  .recommended_books_title {
    display: flex;
    width: 80%;
    margin: 40px auto 20px auto;
    flex-direction: row;
    align-items: center;
    color: #151515;
    font-size: 16px;
    font-weight: bold;
    span {
      background-color: #4aa9ff;
      width: 4px;
      height: 18px;
      display: flex;
      margin-right: 8px;
      border-radius: 2px;
    }
  }
}
</style>